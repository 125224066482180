<!-- eslint-disable import/no-unresolved -->
<template>
  <div v-if="userProfile.profile">
    <validation-observer ref="generalTab">
      <b-card id="personalInfo-section">
        <b-form>
          <h4 class="mb-2">
            {{ $t('residency_introduction') }}
          </h4>
          <b-row>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                label-for="residency-country"
                :label="$t('residency_country')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('residency_country')"
                  vid="residency-country"
                  rules="required"
                >
                  <v-select
                    id="residency-country"
                    v-model="userProfile.residency"
                    :dir="!$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="formsListData.countries"
                    :disabled="!$store.state.profile.profileInfo.edit_profile"
                    :placeholder="$t('residency_country')"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                label-for="citizenship-country"
                :label="$t('citizenship_country')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('citizenship_country')"
                  vid="citizenship-country"
                  rules="required"
                >
                  <v-select
                    id="citizenship-country"
                    v-model="userProfile.citizenship"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :disabled="!$store.state.profile.profileInfo.edit_profile"
                    :options="formsListData.countries"
                    :placeholder="$t('citizenship_country')"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                :label="$t('city')"
                label-for="city"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('city')"
                  vid="city"
                  rules="required"
                >
                  <b-form-input
                    v-model="userProfile.city"
                    :disabled="!$store.state.profile.profileInfo.edit_profile"
                    :placeholder="$t('city')"
                    name="city"
                    @keypress="onlyAlpha($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

        <!-- allProfileInfo.form -->
        <b-form class="mt-2">
          <h4 class="mb-2">
            {{ $t('personal_information') }}
          </h4>
          <b-row>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                :label="$t('first_name')"
                label-for="FirstName"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('first_name')"
                  vid="first_name"
                  rules="required"
                >
                  <b-form-input
                    v-model="userProfile.first_name"
                    :placeholder="$t('first_name')"
                    name="FirstName"
                    :disabled="!$store.state.profile.profileInfo.edit_profile"
                    autocomplete="nope"
                    @keypress="onlyAlpha($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ First Name -->
            <!-- Last Name -->
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                :label="$t('last_name')"
                label-for="LastName"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('last_name')"
                  vid="last_name"
                  rules="required"
                >
                  <b-form-input
                    v-model="userProfile.last_name"
                    name="LastName"
                    :disabled="!$store.state.profile.profileInfo.edit_profile"
                    :placeholder="$t('last_name')"
                    autocomplete="nope"
                    @keypress="onlyAlpha($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ First Name -->
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                label-for="example-datepicker"
                :label="$t('birth_date')"
              >
                <validation-provider
                  v-slot="{ errors }"
                  ref="birthRef"
                  :name="$t('birth_date')"
                  vid="birth_date"
                  rules="required"
                >
                  <date-picker
                    id="birth_date"
                    v-model="convertedBirthdate"
                    :color="primaryColor"
                    :locale="citizenshipName === 'Iran' ? 'fa' : 'en'"
                    type="date"
                    :disabled="!$store.state.profile.profileInfo.edit_profile || !citizenshipName"
                    :placeholder="$t('birth_date')"
                    min="1300/01/01"
                    :initial-value="jalaali().format('jYYYY/jMM/jDD')"
                    auto-submit
                  />
                  <!-- citizenshipName === 'Iran' ?  -->
                  <small
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                label-for="gender"
                :label="$t('gender')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('gender')"
                  vid="Gender"
                  rules="required"
                >
                  <v-select
                    id="v"
                    v-model="userProfile.profile.gender"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    label="value"
                    :options="formsListData.gender"
                    :disabled="!$store.state.profile.profileInfo.edit_profile"
                    :placeholder="$t('gender')"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Email -->
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                :label="$t('email')"
                label-for="account-e-mail"
              >
                <b-form-input
                  v-model="userProfile.email"
                  name="email"
                  :placeholder="$t('email')"
                  :disabled="true"
                  autocomplete="nope"
                />

              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                v-if="!pending"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                :disabled="!$store.state.profile.profileInfo.edit_profile"
                @click="submit"
              >
                {{ $t('save_changes') }}
              </b-button>

              <b-button
                v-if="pending"
                variant="primary"
                class="mt-2 mr-1"
                disabled
              >
                <b-spinner
                  small
                  type="grow"
                />
                <span>{{ $t('loading') }}</span>
              </b-button>
            </b-col>
            <!--/ birth date -->
          </b-row>
        </b-form>
      </b-card>
    </validation-observer>
    <ValidationObserver ref="mobileActivationForm">
      <b-card :title="$t('mobile_number')">
        <b-alert
          class="p-2 mt-2"
          style="height: 100%;position: relative;"
          show
          variant="warning"
        >
          <span> {{ $t('add-mobile-section-change-message') }}</span>
        </b-alert>
        <b-form class="mt-2">

          <p style="margin-right: 3px;">
            {{ $t('guide') }} : {{ $t('guide-desc') }}
          </p>
          <b-row>
            <b-col
              md
              sm="12"
            >
              <b-form-group
                label-for="mobile-country-code"
                :label="$t('mobile_country_code')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('mobile_country_code')"
                  vid="mobile_country_code"
                  rules="required"
                >
                  <v-select
                    id="mobile-country-code"
                    v-model="mobile_country_code"
                    label="precode"
                    :filter-by="myFilter"
                    :options="formsListData.pre_country_code"
                    :placeholder="$t('mobile_country_code')"
                    autocomplete="nope"
                    :disabled="$store.state.profile.profileInfo.mobile_is_verify"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  >
                    <template
                      slot="option"
                      slot-scope="option"
                      class="w-100 text-left"
                    >
                      <div
                        dir="ltr"
                        class="w-100 d-flex "
                      >
                        <img
                          :src="option.image"
                          class="language-image"
                        >
                        &nbsp;
                        <span class="select d-block">+{{ option.precode }}</span>
                        <span class=" d-block">({{ option.name }})</span>
                      </div>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            </b-col>
            <b-col
              md
              sm="12"
            >
              <b-form-group
                label-for="mobile-number"
                :label="$t('mobile_number')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('mobile_number')"
                  vid="phone"
                  rules="required|noZeroStater"
                >
                  <b-form-input
                    id="mobile-number"
                    v-model="mobile_number"
                    class="form-control"
                    :placeholder="$t('mobile_number')"
                    autocomplete="nope"
                    minlength="3"
                    maxlength="15"
                    :disabled="$store.state.profile.profileInfo.mobile_is_verify"
                    @keypress="onlyNumbers($event)"
                  />
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="auto"
              sm="12"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                variant="primary"
                class="mt-2 mr-1 px-3"
                :disabled="$store.state.profile.profileInfo.mobile_is_verify"
                @click="SendCode"
              >
                <div v-if="!codePending">
                  {{ $t('send_code') }}
                </div>
                <div v-else>
                  <b-spinner
                    small
                    type="grow"
                  />
                  <span>{{ $t('loading') }}</span>
                </div>
              </b-button>
            </b-col>
          </b-row>
          <b-row
            v-if="($store.state.profile.profileInfo.mobile && !$store.state.profile.profileInfo.mobile_is_verify) && show"
            class="d-flex justify-content-center mt-3 mt-xl-0"
          >
            <b-col
              cols="12"
              class="mt-2"
            >
              <p class="text-success h5">
                {{ $t('input_received_otpcode_here', {
                  phone: `${$store.state.profile.profileInfo.mobile_country_code}${$store.state.profile.profileInfo.mobile}` })
                }}
              </p>
            </b-col>
            <b-col
              md
              sm="12"
            >
              <b-form-group
                label-for="receive-code"
                :label="$t('receive_code')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('receive_code')"
                  vid="receive-code"
                  rules="required"
                >
                  <b-form-input
                    id="receive-code"
                    ref="receive_code"
                    v-model="receive_code"
                    class="form-control"
                    :placeholder="$t('receive_code_placeholder')"
                    autocomplete="nope"
                    minlength="3"
                    maxlength="15"
                    @keypress="onlyNumbers($event)"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            </b-col>
            <b-col
              md="auto"
              sm="12"
            >
              <b-button
                block
                variant="primary"
                class="mt-2 mr-1 px-3"
                @click="RegisterCode"
              >
                <div v-if="!registerPending">
                  {{ $t('code_registration') }}
                </div>
                <div v-else>
                  <b-spinner
                    small
                    type="grow"
                  />
                  <span>{{ $t('loading') }}</span>
                </div>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BSpinner,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required, noZeroStater } from '@validations'
import { ValidationObserver } from 'vee-validate'
import jalaali from 'moment-jalaali'
import DatePicker from 'vue-persian-datetime-picker'
import moment from '@/libs/moment'
import { alphanumeric } from '@/mixins/alphanumeric'
import ProfileApis from '@/services/apis/profile'
import FormApis from '@/services/apis/form'
import { toEnglishDigits } from '@/utils'
// import tour from '@/mixins/tour'
// import tour from '@/mixins/tour'
const formData = new FormApis()
const userProfile = new ProfileApis()
export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BSpinner,
    vSelect,
    ValidationObserver,
    DatePicker,
  },
  directives: {
    Ripple,
  },
  mixins: [alphanumeric],
  data() {
    return {
      required,
      noZeroStater,
      moment,
      jalaali,
      show: false,
      pending: false,
      codePending: false,
      registerPending: '',
      receive_code: '',
      mobile_country_code: '',
      mobile_number: '',
      residency: '',
      citizenship: '',
      userProfile: {
        profile: {},
      },
      formsListData: {
        gender: [],
        countries: [],
        pre_country_code: [],
      },
      convertedBirthdate: jalaali().format('jYYYY/jMM/jDD'),
    }
  },

  computed: {
    profileInfoP() {
      return this.$store.state.profile.profileInfo
    },
    primaryColor() {
      return process.env.VUE_APP_PRIMARY_COLOR
    },
    citizenshipName() {
      return this.userProfile?.citizenship?.name
    },
  },

  watch: {
    profileInfoP: {
      handler(val) {
        if (val) {
          this.userProfile = val
          this.mobile_number = val.mobile
          this.mobile_country_code = { precode: val.mobile_country_code }
          // console.log(this.userProfile.profile.birth_date, jalaali(this.userProfile.profile.birth_date, 'YYYY-MM-DD'))
          if (this.citizenshipName === 'Iran') {
            this.convertedBirthdate = jalaali(this.userProfile.profile.birth_date, 'YYYY-MM-DD').format('jYYYY-jMM-jDD')
          } else {
            this.convertedBirthdate = jalaali(this.userProfile.profile.birth_date, 'YYYY-MM-DD').format('jYYYY-jMM-jDD')
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    formData.allproperties().then(res => {
      const result = res.data.results
      this.formsListData = result
      this.formsListData.pre_country_code = result.countries.map(item => ({
        // eslint-disable-next-line global-require, import/no-unresolved, import/no-dynamic-require
        precode: item.precode, id: item.id, name: item.name, image: require(`@/assets/images/flags/${item.abbreviation}.png`),
      }))
    })
    if (this.profileInfoP && this.profileInfoP.profile) {
      this.userProfile = this.profileInfoP
      this.mobile_number = this.profileInfoP.mobile
      this.mobile_country_code = { precode: this.profileInfoP.mobile_country_code }
      // console.log(this.profileInfoP.profile.birth_date, jalaali(this.profileInfoP.profile.birth_date, 'YYYY-MM-DD'))
      if (this.citizenshipName === 'Iran') {
        this.convertedBirthdate = jalaali(this.profileInfoP.profile.birth_date, 'YYYY-MM-DD').format('jYYYY-jMM-jDD')
      } else {
        this.convertedBirthdate = jalaali(this.profileInfoP.profile.birth_date, 'YYYY-MM-DD').format('jYYYY-jMM-jDD')
      }
    }
  },
  methods: {
    async submit() {
      if (moment().diff(moment(jalaali(this.convertedBirthdate, 'jYYYY/jMM/jDD').format('YYYY-MM-DD')), 'days') <= moment().diff(moment().subtract(18, 'years').format('YYYY-MM-DD'), 'days')) {
        await this.$refs.generalTab.refs.birth_date.applyResult({
          errors: [this.$t('user_under_age_cant_register')], // array of string errors
          valid: false, // boolean state
          failedRules: {}, // should be empty since this is a manual error.

        })
        return
      }
      const success = await this.$refs.generalTab.validate()
      if (success) {
        this.pending = true
        const data = this.getData()
        userProfile.saveGeneral(data).then(res => {
          this.$swal({
            title: res.data.message,
            icon: 'success',
            timer: 2500,
            showConfirmButton: false,
          })
          if (res.status === 201) {
            this.$emit('update:active', 16)
          }
          userProfile.get().then(() => {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            })
          })
          this.pending = false
        }).catch(() => {
          this.pending = false
        })
      }
    },

    getData() {
      return {
        residency_id: this.userProfile.residency.id,
        citizenship_id: this.userProfile.citizenship.id,
        city: this.userProfile.city,
        first_name: this.userProfile.first_name,
        last_name: this.userProfile.last_name,
        gender_id: this.userProfile.profile.gender.id,
        birth_date: jalaali(this.convertedBirthdate, 'jYYYY/jMM/jDD').format('YYYY-MM-DD'),
      }
    },
    myFilter: (option, label, search) => {
      const temp = search.toLowerCase()
      return option.name.toLowerCase().indexOf(temp) > -1
          || option.precode.toString().toLowerCase().indexOf(temp) > -1
    },
    async SendCode() {
      this.codePending = true
      const data = { mobile: toEnglishDigits(this.mobile_number), mobile_country_code: this.mobile_country_code ? toEnglishDigits(this.mobile_country_code?.precode) : undefined }
      await userProfile.acceptMobile(data).then(res => {
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
        userProfile.get()
        this.show = true
        // after input showed in dom, then select it.
        this.$nextTick(() => {
          const input = this.$refs.receive_code
          if (input) {
            input.focus()
          }
        })

        this.codePending = false
      }).catch(() => {
        this.codePending = false
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
    async RegisterCode() {
      this.registerPending = true
      const data = { code: toEnglishDigits(this.receive_code) }
      await userProfile.verifyMobile(data).then(res => {
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,

        })
        userProfile.get()
        this.registerPending = false
        this.show = false
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }).finally(() => {
        this.registerPending = false
      })
    },
  },

}
</script>
<style>
.select {
  margin-right: 5px;
}
</style>
